import { Role } from '@wix/ambassador-members-v1-role/types';
import { FunctionContext } from '@wix/yoshi-flow-editor/build/cjs/serverless';
import { Member } from './member.type';

export const getBlankMember = (memberId: string): Member => {
  return {
    id: memberId,
    name: 'Unknown member',
    siteMemberId: memberId,
    isBlocked: false,
    rolesList: [],
    badges: [],
    groups: [],
    permissions: { role: 'user' as const },
  };
};

export const hasAdminRole = (roles: Role[]): boolean => {
  return !!roles.find((r) => r.roleKey === 'ADMIN');
};

export const hasForumModeratorRole = (roles: Role[]): boolean => {
  return !!roles.find((r) => r.roleKey === 'FORUM_MODERATOR');
};

export const hasBlockedRole = (roles: Role[]): boolean => {
  return !!roles.find((r) => r.roleKey === 'BLOCKED');
};

export const buildPermissions = (roleList: ('admin' | 'forum_moderator')[]) => {
  if (roleList) {
    if (roleList.includes('admin')) {
      return { role: 'owner' as const };
    } else if (roleList.includes('forum_moderator')) {
      return { role: 'moderator' as const, moderate: ['all'] };
    }
  }

  return { role: 'user' as const };
};

export const isUserLoggedIn = async (context: FunctionContext): Promise<boolean> => {
  const identityResponse = await context.apiGatewayClient.identity(context.aspects);

  return !!(identityResponse.siteMemberIdentity || identityResponse.userIdentity);
};
